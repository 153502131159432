<template>
	<div>
		<!-- 代付 -->
		<div class="queryBox">
			<div class="">
				<span>{{$t('main.orderMenu.paymentMethod')}}：</span>
				<el-select v-model="pay_type" :range-separator="$t('main.orderMenu.pleaseSelectState')" size="mini" class="mr10" clearable>
					<el-option v-for="(item,index) in pay_type_arr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				
				<span>{{$t('main.orderMenu.passageway')}}：</span>
				<el-select v-model="payway_type_id" multiple  :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr10" clearable>
					<el-option v-for="item in paywayTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				
				<span>商户状态：</span>
				<el-select v-model="state" :range-separator="$t('main.orderMenu.pleaseSelectState')" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				<span>通道状态：</span>
				<el-select v-model="hd_state" :range-separator="$t('main.orderMenu.pleaseSelectState')" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				<span>通道上报：</span>
				<el-select v-model="report_state" :range-separator="$t('main.orderMenu.pleaseSelectState')" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in reportStateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantNumber')" v-model="business_no" clearable class="mr20" style="width: 160px"></el-input>
				<el-input size="mini" placeholder="运营商" v-model="operator_id" clearable class="mr20" style="width: 160px"></el-input>
				<el-input size="mini" placeholder="地区" v-model="prov_code" clearable class="mr20" style="width: 160px"></el-input>
				

			</div>
			<div class="mb10">
				<br />
				<el-button size="mini" plain class="mr10" @click="setDate(-1)" :disabled="disLoading">{{$t('main.orderMenu.theDayBefore')}}</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" :range-separator="$t('main.orderMenu.to')" start-placeholder="开始日期"
				 end-placeholder="结束日期" class="mr10"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">{{$t('main.orderMenu.theNextDay')}}</el-button>
				
				<el-input size="mini" placeholder="最小金额" v-model="min_money" clearable style="width: 100px"></el-input>
				-
				<el-input size="mini" placeholder="最大金额" v-model="max_money" clearable class="mr20" style="width: 100px"></el-input>
				<el-input size="mini" placeholder="商户/系统/通道订单号" v-model="order_water_no" clearable class="mr10" style="width: 200px"></el-input>
				
				<el-button size="mini" :icon="disLoading?'el-icon-loading':'el-icon-brush'" @click="resetCondition" :disabled="disLoading">重置</el-button>
				
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">{{$t('main.orderMenu.query')}}</el-button>
				<el-button size="mini" type="primary" :disabled="!chooseList.length"  @click="submitAllOrder" >{{$t('main.orderMenu.batchOrderNotSubmittedForProcessing')}}</el-button>
				<el-button size="mini" type="primary" icon="el-icon-document" @click="getExcel" :disabled="tableData.length == 0">{{$t('main.orderMenu.exportExcel')}}</el-button>
				<el-button size="mini" type="primary" @click="copyOrderNumbers">一键复制订单号</el-button>
			</div>
			<div>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>订单总数</span>
							<span>{{ dataTotal.add_count }}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>成功单数</span>
							<span>{{ dataTotal.success_count }}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>成功金额</span>
							<span>{{ dataTotal.success_money | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>商户手续费</span>
							<span>{{ Number(dataTotal.fee_gold_tra)+Number(dataTotal.fee_gold_num)  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>商户扣除</span>
							<span>{{ dataTotal.trader_gold  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>通道利润</span>
							<span>{{ Number(dataTotal.fee_gold_payway_type)+Number(dataTotal.fee_gold_payway_type_num)  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>代理利润</span>
							<span>{{ Number(dataTotal.fee_gold_ag)+Number(dataTotal.fee_gold_ag_num)  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="success" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>平台利润</span>
							<span>{{ dataTotal.fee_gold_adm  | tofixed}}</span>
						</div>
				    </el-tag>
				    <el-tag type="warning" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>成功率</span>
							<span>{{ dataTotal.success_ratio }}%</span>
						</div>
				    </el-tag>
				    <el-tag @click="checkPendingOrder('pending')" type="danger" effect="plain" class="custom-tag mr10">
						<div class="flexY">
							<span>充值成功&上报失败</span>
							<span>{{ dataTotal.pending_count }}</span>
						</div>
				    </el-tag>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" size="mini"  :row-class-name="getRowClassName" v-loading="loading" @selection-change="handleSelectionChange">
				<el-table-column
					fixed
					type="selection"
					:selectable='noSelecTable'
					v-if="state == 2 || state == 7"
					width="55">
				</el-table-column>
				<el-table-column fixed label="订单号" prop="order_id" width="120px">
					<template slot-scope="scope">
						<span v-if="scope.$index < tableData.length-2" class="flexY flexStart">
							<div class="flexX flexStart">
								<div class="yctable" >商户:{{scope.row.order_id}}</div>
								<span><i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.order_id)"></i></span>
							</div>
							<div class="flexX flexStart">
								<div class="yctable" >系统:{{scope.row.water_no}}</div>
								<span><i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.water_no)"></i></span>
							</div>
							<div class="flexX flexStart">
								<div class="yctable" >通道:{{scope.row.bill_no}}</div>
								<span v-if="scope.row.bill_no"><i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.bill_no)"></i></span>
							</div>
							
						</span>
						<span v-if="scope.$index == tableData.length-2">单页合计</span>
						<span v-if="scope.$index == tableData.length-1">总计</span>
					</template>
				</el-table-column>
				<el-table-column label="通道信息" width="130px">
					<template slot-scope="scope" v-if="scope.row.id">
						产品:{{scope.row.pay_type_name}}<br>
						通道:
						<span v-if="scope.row.payway_name" class="csuccess">{{scope.row.payway_name}}</span>
						<span v-if="!scope.row.payway_name" class="cdanger">未分配通道</span><br>
						账号：{{scope.row.payway_account}}
					</template>
				</el-table-column>
				<el-table-column label="通道状态" width="160px">
					<template slot-scope="scope">
						<div class="flexX flexStart">
							<div class="yctable" >
								话单:<span v-if="scope.row.hd_state== '充值成功'" class="csuccess">{{scope.row.hd_state}}</span>
								<span v-else-if="scope.row.hd_state== '充值失败'" class="cdanger">{{scope.row.hd_state}}</span>
								<span v-else-if="scope.row.hd_state== '取单失败'" class="cwarning">{{scope.row.hd_state}}</span>
								<span v-else class="cprimary">{{scope.row.hd_state}}</span>
							</div>
						</div>
						<div class="flexX flexStart">
							<div class="yctable" >
								上报:<span v-if="scope.row.report_state== '上报成功'" class="csuccess">{{scope.row.report_state}}</span>
								<span v-else-if="scope.row.report_state== '上报失败'" class="cdanger">{{scope.row.report_state}}</span>
								<span v-else class="cprimary">{{scope.row.report_state}}</span>
							</div>
							
						</div>
					</template>
				</el-table-column>
				<el-table-column label="通道时间" width="160px">
					<template slot-scope="scope">
						<div class="flexX flexStart">
							<div class="yctable" >取单:{{scope.row.pw_get_time}}</div>
						</div>
						<div class="flexX flexStart">
							<div class="yctable" >上报:{{scope.row.pw_report_time}}</div>
						</div>
						<div class="flexX flexStart">
							<div v-if="scope.row.pw_report_time && scope.row.pw_get_time" class="yctable" >耗时:{{getTimeDifference(scope.row.pw_get_time,scope.row.pw_report_time)}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="商户信息">
					<template slot-scope="scope">
						{{scope.row.business_no}}<br>
						{{scope.row.business_name}}
					</template>
				</el-table-column>
				<el-table-column label="商户状态">
					<template slot-scope="scope">
						<span v-if="scope.row.state== 1" class="csuccess">{{scope.row.state_name}}</span>
						<span v-else-if="scope.row.state== 7" class="cdanger">{{scope.row.state_name}}</span>
						<span v-else-if="scope.row.state== 4" class="cwarning">{{scope.row.state_name}}</span>
						<span v-else class="cprimary">{{scope.row.state_name}}</span>
					</template>
				</el-table-column>
				<el-table-column label="商户时间" width="160px">
					<template slot-scope="scope">
						<div class="flexX flexStart">
							<div class="yctable" >创建:{{scope.row.add_time}}</div>
						</div>
						<div class="flexX flexStart">
							<div class="yctable" >取单:{{scope.row.tra_get_time}}</div>
						</div>
						<div class="flexX flexStart">
							<div class="yctable" >上报:{{scope.row.tra_report_time}}</div>
						</div>
						<div class="flexX flexStart">
							<div v-if="scope.row.tra_report_time && scope.row.tra_get_time" class="yctable" >耗时:{{getTimeDifference(scope.row.tra_get_time,scope.row.tra_report_time)}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="话单信息" width="130px">
					<template slot-scope="scope">
						<div>
							{{scope.row.operator_id}}
							<br>
							{{scope.row.mobile}}
							<br>
							{{scope.row.prov_code}}
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('main.orderMenu.orderAmount')">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
							<span>{{scope.row.gold | tofixed}}</span>
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							<span>{{scope.row.gold | tofixed}}</span>
						</div>
						<p v-else>{{scope.row.gold | tofixed}}</p>
					</template>
				</el-table-column>
				<!-- <el-table-column :label="$t('main.orderMenu.singleHandlingCharge')">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
							<span>{{scope.row.fee_gold_num | tofixed}}</span>
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							<span>{{scope.row.fee_gold_num | tofixed}}</span>
						</div>
						<p v-else>{{scope.row.fee_gold_num | tofixed}}</p>
					</template>
				</el-table-column>
				<el-table-column label="实际扣除">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
							<span>{{scope.row.trader_gold | tofixed}}</span>
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							<span>{{scope.row.trader_gold | tofixed}}</span>
						</div>
						<p v-else>{{scope.row.trader_gold | tofixed}}</p>
					</template>
				</el-table-column> -->
				
				<el-table-column fixed='right' :label="$t('main.orderMenu.remarks')" show-overflow-tooltip>
					<template slot-scope="scope">
						<div v-if="scope.row.is_rush==1">
							{{scope.row.rush_note}}
						</div>
						<div class="flexY" v-if="scope.row.is_rush!=1 && scope.row.note && isValidJSON(scope.row.note)">
							<span v-for="val of JSON.parse(scope.row.note)" > {{val}} <br /></span>
						</div>
						<div class="flexY" v-if="scope.row.is_rush!=1 && scope.row.note && !isValidJSON(scope.row.note)">
							<span> {{scope.row.note}} </span>
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('main.orderMenu.handler')" prop="auditor" > </el-table-column>
				<el-table-column fixed='right' :label="$t('main.orderMenu.function')" width="120px">
					<template slot-scope="scope">
						<div class="flexY flexStart">
							<div v-if="scope.row.id" class="mb10"><el-button size="mini" @click="closeOpenDialog(scope.row,scope.$index)">上报</el-button></div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX pageBox mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
		
		<el-dialog center class="dialog" top="150px" :title="rushType == 'budan'?'补单':'冲正订单'" :visible.sync="dialogVisible2" @close="close2" :close-on-click-modal="false">
			<div v-loading="dialogloading">
				<div class="textCenter">{{rushType == 'budan'?'补单原因':'冲正原因'}}</div>
				<div class="mt40" style="margin: 40px auto 0;">
					<el-input type="textarea" :rows="5" :placeholder="rushType == 'budan'?'请输入补单原因':'请输入冲正原因'" v-model="note"></el-input>
				</div>
			</div>
			<div class="textCenter mt40">
				<el-button type="primary" @click="close2">取消</el-button>
				<el-button type="primary" @click="suerOrderRush">确认</el-button>
			</div>
		</el-dialog>
		<el-dialog center class="dialog" top="150px" title="审核" :visible.sync="reviewVisible" :close-on-click-modal="false"
		 @close="reviewClose">
			<div v-loading="reviewloading">
				<div class="reviewBox flexX flexAround flexcenter">
					<div>
						<div class="flexX flexcenter"><div class="mr20">账号：</div><div>{{item.name}}</div></div>
						<div class="mt20 flexX flexcenter"><div class="mr20">卡号：</div><div>{{item.card}}</div></div>
						<div class="mt20 flexX flexcenter"><div class="mr20">姓名：</div><div>{{item.type_name}}</div></div>
						<div class="mt20 flexX flexcenter"><div class="mr20">银行：</div><div>{{item.bank}}</div></div>
						<div class="mt20 flexX flexcenter"><div class="mr20">{{$t('main.orderMenu.orderAmount')}}：</div><div>{{item.gold | tofixed}}</div></div>
					</div>
				</div>
				<div class="textCenter mt40">
					<el-button type="primary" @click="reviewCloseOrder">审核关闭</el-button>
					<el-button type="primary" @click="reviewPass">审核通过</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog center class="dialog" top="150px" title="手动代付" :visible.sync="stateVisible" :close-on-click-modal="false">
			<div>
				<el-radio-group v-model="payType">
					<template v-for="item of paywayTypeList">
						<el-radio :label="item.id" :key="item.id">{{item.name}}</el-radio>
					</template>
				</el-radio-group>
			</div>
	
			<div class="textCenter mt40">
				<el-button type="primary" @click="submitPaytype">确定</el-button>
			</div>
		</el-dialog>
		<el-dialog center class="dialog" top="150px" title="修改金额"
			:visible.sync="changeDialog" @close="closeChange" :close-on-click-modal="false">
			<div v-loading="dialogloading" style="margin: 0 auto;">
				<div class="textCenter mt20">
					<span class="textCenter">修改金额：</span>
					<span>
						<el-input style="width:50%" placeholder="请输入修改金额" v-model="changeMoney"></el-input>
					</span>
				</div>
				<div class="textCenter mt40">
					<el-button type="primary" @click="closeChange">取消</el-button>
					<el-button type="primary" @click="sureChange">确认</el-button>
				</div>
			</div>
		</el-dialog>
		
		<el-dialog width="30%" center class="dialog" top="100px" title="上报" :visible.sync="dialogVisible3" @close="close3" :close-on-click-modal="false">
			<div v-loading="dialogloading">
				<div class="reviewBox flexX flexAround flexcenter">
					<div>
						<div class="mt10 flexX flexcenter"><div class="mr20">商户订单号：</div><div>{{closeItem.order_id}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">系统订单号：</div><div>{{closeItem.water_no}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">通道订单号：</div><div>{{closeItem.bill_no}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">号码：</div><div>{{closeItem.mobile}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">运营商：</div><div>{{closeItem.operator_id}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">地区：</div><div>{{closeItem.prov_code}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">金额：</div><div>{{closeItem.gold}}</div></div>
						<div v-if="closeItem.state" class="mt10 flexX flexcenter">
							<div class="cdanger">商户状态：【{{closeItem.state_name}}】</div>
						</div>
						<div v-if="closeItem.state" class="mt10 flexX flexcenter">
							<div class="cdanger">通道状态：【{{closeItem.hd_state}}】</div>
						</div>
						<div class="mt10 flexX flexcenter">
							<div class="mr10">上报状态：</div>
							<div>  
								<el-radio v-model="pwRadio" label="1">充值成功</el-radio>
								<el-radio v-model="pwRadio" label="7">充值失败</el-radio>
							</div>
						</div>
						<div class="mt10 flexX flexcenter">
							<div class="mr10">验证码：</div>
							<div><el-input placeholder="请输入谷歌验证码" v-model="googleCode"></el-input></div>
						</div>
						
					</div>
				</div>	
				<div class="textCenter mt40">
					<el-button type="primary" @click="close3()">取消</el-button>
					<el-button type="primary" @click="closeMainOrder()">确认上报</el-button>
				</div>
			</div>
		</el-dialog>
		
		<el-dialog width="30%" center class="dialog" top="100px" title="解绑通道" :visible.sync="dialogVisible4" @close="close4" :close-on-click-modal="false">
			<div v-loading="dialogloading">
				<div class="reviewBox flexX flexAround flexcenter">
					<div>
						<div class="mt10 flexX flexcenter"><div class="mr20">商户订单号：</div><div>{{unbildItem.order_id}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">系统订单号：</div><div>{{unbildItem.water_no}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">姓名：</div><div>{{unbildItem.name}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">卡号：</div><div>{{unbildItem.card}}</div></div>
						<div class="mt10 flexX flexcenter"><div class="mr20">银行：</div><div>{{unbildItem.bank}}</div></div>
						<div class="mt10 flexX flexcenter">
							<div class="mr10">金额：</div>
							<div>{{unbildItem.gold | tofixed}}</div>
						</div>
						<div v-if="unbildItem.payway_type_id > 0" class="mt10 flexX flexcenter">
							<div class="cdanger">已绑通道：【{{unbildItem.payway_name}}】,谨慎解绑！</div>
						</div>
						<!-- <div class="mt10 flexX flexcenter">
							<div class="mr10">解绑原因：</div>
							<div><el-input type="textarea" :rows="2" placeholder="请输入驳回原因" v-model="unbildReason"></el-input></div>
						</div> -->
						<div class="mt10 flexX flexcenter">
							<div class="mr10">验证码：</div>
							<div><el-input placeholder="请输入谷歌验证码" v-model="googleCode"></el-input></div>
						</div>
						
					</div>
				</div>	
				<div class="textCenter mt40">
					<el-button type="primary" @click="close4()">取消</el-button>
					<el-button type="primary" @click="unbildMainOrder()">解绑</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				order_id: '',
				water_no: '',
				business_no: '',
				dfName: '',
				dfCard: '',
				min_money: '',
				max_money: '',
				card_name: '',
				business_name: '',
				add_gold: '',
				req_ip: '',
				pay_type: '',
				state: '',
				paywayArr: [],
				stateArr: {},
				pay_type_arr:{},
				// stateMap:{},
				date: null,
				countAll: {},
				diapageSizesArr: [20, 50, 100, 200],
				diapageSize: 20,
				diatotal: 0,
				diapage: 1,
				nodepageSizesArr: [20, 50, 100, 200],
				nodepageSize: 20,
				nodetotal: 0,
				nodepage: 1,
				outpageSizesArr: [20, 50, 100, 200],
				outpageSize: 20,
				outtotal: 0,
				outpage: 1,
				billpageSizesArr: [20, 50, 100, 200],
				billpageSize: 20,
				billtotal: 0,
				billpage: 1,
				dialogVisible: false,
				dialogloading: false,
				item: {},
				infoTotalData: {}, //搬砖工
				cardListData: [], //银行卡列表
				cardNoteData: [], //银行卡记录  流水
				cardNoteOutData: [], //卡出账记录 
				cardBillData: [], //卡账单
				bill_update_time: '', //卡账单 最后更新时间
				activeName: 'info',
				//冲正
				dialogVisible2: false,
				note: '',
				//审核
				reviewVisible: false,
				reviewloading: false,
				pIndex: '',
				sIndex: '',
				rushType:'',
				height: 0,
				stateVisible:false,
				chooseList:[],
				paywayTypeList:[],
				payType:'',
				// 通道筛选
				payway_type_id:'',	
				// 修改金额
				changeDialog: false,
				changeMoney: '',
				changeItem: {},
				order_water_no: '',// 商户、系统的订单号
				// 关闭订单
				dialogVisible3: false,
				closeReason: '',
				googleCode: '',
				closeItem:[],
				// 数据统计.
				dataTotal: [],
				interval: null,
				// 解绑处理中订单的通道
				dialogVisible4: false,
				unbindReason: '',
				unbildItem: [],
				// 上报
				pwRadio: '1',
				reportStateArr:{
						1:'上报成功',
						2:'上报失败'
					},
			    hd_state:'',
				report_state:'',
				operator_id:'',
				prov_code:'',
				
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
			this.stateList()
			this.repayTypeArr()
			this.paywayTypeAll()
			this.orderOutList()
			if (this.interval) {
				clearInterval(this.interval);
			  }
			this.orderOutTotal()
		    this.interval = setInterval(() => {
				this.orderOutTotal()
		    }, 10000);
			  
			
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})

		},
		mounted(){
			let self = this
			document.onkeydown = function(e) {
			  let ev = document.all ? window.event : e
			  if (ev.keyCode === 13) {
				  if(!self.loading){
					  self.getData()
				  }
				
			  }
			}
		},
		beforeDestroy() {
		    if (this.interval) {
		      clearInterval(this.interval);
		    }
		},
		destroyed() {
			document.onkeydown = null
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					// return ((Math.floor((value * 1)*100)/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			copyOrderNumbers() {
			  // 提取所有订单号
			  const orderNumbers = this.tableData.map(item => item.water_no).join('\n');
			  
			  // 复制到剪贴板
			  navigator.clipboard.writeText(orderNumbers).then(() => {
				this.$message.success('订单号已复制到剪贴板');
			  }).catch(err => {
				this.$message.error('复制失败');
			  });
			},
			resetCondition(){
				
				this.operator_id = ''
				this.prov_code = ''
				this.hd_state = ''
				this.report_state = ''
				this.pay_type = ''
				this.state = '';
				this.payway_type_id = [];
				this.business_no = ''
				this.dfName = ''
				this.dfCard = ''
				this.min_money = ''
				this.max_money = ''
				this.order_water_no = ''
				this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
				this.getData()
			},
			checkPendingOrder(type){
				if(type == 'pending'){
					this.hd_state = '1';
					this.report_state = '2';
					this.payway_type_id = [];
				}
				this.getData()
			},
			orderOutTotal() {
				let data = {
					type: "1,2,9",
					// order_id: this.order_id,
					business_no: this.business_no,
					business_name: this.business_name,
					// state: this.state,
					pay_type: this.pay_type,
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					// page: this.page,
					// size: this.pageSize,
					// water_no:this.water_no,
					payway_type_id:this.payway_type_id,
					name:this.dfName,
					card:this.dfCard,
					min_gold:this.min_money,
					max_gold:this.max_money,
					order_water_no:this.order_water_no,
				}
				this.$api.orderHfTotal(data).then(res => {
					if (res.status === 1) {
						this.dataTotal = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			// 判断是否是正确的json格式字符串
			isValidJSON(jsonString) {
			  try {
				JSON.parse(jsonString);
				return true;
			  } catch (e) {
				return false;
			  }
			},
			// 复制
			handleCopy(textCopy){
				
				const contentToCopy = textCopy
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			},
			sureChange(){
				let data = {
					id: this.changeItem.id,
					changeGold: this.changeMoney
				}
				this.loading = true
				// return
				this.$api.ChangeOrderGold(data).then(res => {
					this.changeDialog = false
					this.loading = false
					this.$set(this.tableData[this.pIndex], 'gold', this.changeMoney)
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.changeDialog = false
					this.loading = false
					this.$message({
						type: 'error',
						message: res.msg
					})
				})
				
			},
			closeChange(){
				this.changeDialog = false
				this.changeMoney = ''
				this.changeItem = {}
			},
			ChangeGold(item, index){
				this.changeDialog = true
				this.changeItem = item
				this.pIndex = index;
			},
			//通道的筛选项
			paywayTypeAll(){
				this.$api.paywayTypeAll({}).then(res=>{
					this.paywayTypeList = res.data
				})
			},
			submitPaytype(){
				let ids = this.chooseList.map(item => item.water_no)
				let data ={
					payway_type_id: this.payType,
					water_nos: JSON.stringify(ids) 
				}
				this.$api.manualPushPayway(data).then(res=>{
					if (res.status == 1) {
						this.stateVisible=false
						this.orderOutList();
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					// this.paywayTypeList = res.data
				})
			},
			  // 是否禁止选择
			noSelecTable(row, index) {
				// 回显的数据是否可选
				if (row.state !== 2 && row.state !== 7) {
					return false;
				} else {
					return true;
				}
			},
			submitAllOrder(){
				if(this.chooseList.length){
					this.stateVisible=true
				}
			},
			handleSelectionChange(selection, row) {
				this.chooseList = selection
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
			getTimeDifference(startTime, endTime) {
				return this.$util.intervalTime(startTime, endTime)
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderOutList();
				this.orderOutTotal()
			},
			//总计
			orderCountAll() {
				this.$api.orderCountAll({}).then(res => {
					// console.log(res)
					if (res.status == 1) {
						this.countAll = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},

			getRowClassName({
				row,
				rowIndex
			}) {
				if (!row.id) {
					return 'row-expand-cover';
				}
			},

			//导出Excel
			getExcel() {
				//manager/order/exportList
				// console.log(this.$baseURL)
				// let data = {
				let username = local.get('fourusername');
				let tid = local.get('fourtid')
				let start_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '';
				let end_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '';
				// }
				let href =
					`${this.$baseURL}/manager/order/exportOutList?username=${username}&tid=${tid}&order_id=${this.order_id}&water_no=${this.water_no}&business_no=${this.business_no}&name=${this.dfName}&card=${this.dfCard}&min_gold=${this.min_money}&max_gold=${this.max_money}&card_name=${this.card_name}&business_name=${this.business_name}&add_gold=${this.add_gold}&req_ip=${this.req_ip}&pay_type=${this.pay_type}&state=${this.state}&start_time=${start_time}&end_time=${end_time}&type=1,2,9`
				// console.log(href)
				window.location.href = href
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderOutList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderOutList()
			},
			diahandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.diapageSize = val
				this.diapage = 1
				this.cardList()
			},
			diahandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.diapage = val
				this.cardList()
			},

			nodehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.nodepageSize = val
				this.nodepage = 1
				this.moneyList()
			},
			nodehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.nodepage = val
				this.moneyList()
			},
			outhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				
				this.outpage = 1
				this.outpageSize = val
				this.outList()
			},
			outhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.outpage = val
				this.outList()
			},
			billhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.billpage = 1
				this.billpageSize = val
				this.cardBill()
			},
			billhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.billpage = val
				this.cardBill()
			},
			paywayAll() {
				this.$api.paywayAll({}).then(res => {
					if (res.status === 1) {
						this.paywayArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			stateList() {
				this.$api.outStateList({}).then(res => {
					if (res.status === 1) {
						this.stateArr = res.data
						// this.stateArr.forEach(item=>{
						// 	this.stateMap[item.id] = item.note
						// })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			repayTypeArr() {
				this.$api.repayTypeAll({}).then(res => {
					if (res.status === 1) {
						this.pay_type_arr = res.data.data
						// this.stateArr.forEach(item=>{
						// 	this.stateMap[item.id] = item.note
						// })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			orderOutList() {
				let data = {
					type: "1,2,9",
					order_id: this.order_id,
					business_no: this.business_no,
					business_name: this.business_name,
					state: this.state, // 商户状态
					hd_state: this.hd_state, // 通道状态
					report_state: this.report_state, // 上报状态
					pay_type: this.pay_type,
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					page: this.page,
					size: this.pageSize,
					water_no:this.water_no,
					payway_type_id:this.payway_type_id,
					order_water_no:this.order_water_no,
					operator_id:this.operator_id,
					prov_code:this.prov_code,
					
				}
				// this.loading = true
				this.disLoading = true
				this.$api.hdAllList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						// res.data.data.forEach(item=>{
						// 	item.add_time_date = item.add_time.spli
						// })
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			gotopage() {
				this.$router.push({
					path: '/orderInfo'
				})
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return 'success-rowT';
				} else {
					return 'success-row';
				}
				// return '';
			},
			//查看代理
			queryAgent(id) {
				// this.dialogloading = true
				this.$api.showAgent({
					carder_id: id
				}).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						let agent = res.data.join(' -> ')
						this.$alert('上级代理为:' + agent, '上级代理', {
							confirmButtonText: '确定',
						});
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.dialogloading = false
				})

			},
			showInfo(item) {
				// this.$router.push({path:'/orderInfo'})
				this.dialogVisible = true;
				this.item = item
				this.infoTotal(item)
			},
			getInfoData(val) {
				if (val.name == 'info') {
					if (JSON.stringify(this.infoTotalData) == '{}') {
						this.infoTotal()
					}
				}
				if (val.name == 'bankList') {
					if (this.cardListData.length == 0) {
						this.cardList()
					}
				}
				if (val.name == 'cardNote') {
					if (this.cardNoteData.length == 0) {
						this.moneyList()
					}
				}
				if (val.name == 'cardNoteOut') {
					if (this.cardNoteOutData.length == 0) {
						this.outList()
					}
				}
				if (val.name == 'cardBill') {
					if (this.cardBillData.length == 0) {
						this.cardBill()
					}
				}
				// console.log('val', val)
			},
			infoTotal(item) {
				let data = {
					id: item.bank_card_id,
					type: 2
				}
				// this.dialogloading = true
				this.$api.infoTotal(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.infoTotalData = res.data
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardList(item) {
				let data = {
					carder_id: this.infoTotalData.id,
					size: this.diapageSize,
					page: this.diapage,
					// type:2
				}

				// this.dialogloading = true
				this.$api.cardList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardListData = res.data.data
						this.diatotal = res.data.total

					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			close() {
				this.dialogVisible = false
				this.item = {}
				this.infoTotalData = {}//搬砖工
				this.cardListData = []//银行卡列表
				this.cardNoteData = []//银行卡记录  流水
				this.cardNoteOutData=[]
				this.cardBillData=[]
				this.activeName = 'info'
				this.diapage = 1
				this.nodepage = 1
				this.outpage = 1
				this.billpage = 1

			},
			close2() {
				this.dialogVisible2 = false
				this.note = ""

			},
			moneyList() {
				let data = {
					page: this.nodepage,
					size: this.nodepageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.moneyList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardNoteData = res.data.data
						this.nodetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			outList() {
				let data = {
					page: this.outpage,
					size: this.outpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.outList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardNoteOutData = res.data.data
						this.outtotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//卡账单
			cardBill() {
				let data = {
					page: this.billpage,
					size: this.billpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.cardBill(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardBillData = res.data.data
						this.billtotal = res.data.total
						this.bill_update_time = res.data.bill_update_time
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cancle(item, type, index, sindex) {
				this.rushType = type
				this.dialogVisible2 = true;
				this.item = item;
				this.pIndex = index;
				this.sIndex = sindex;
			},
			suerOrderRush() {
				/* if (this.rushType == "child") {
					this.rushCard();
				} */
				if (this.rushType == "parent") {
					this.orderRush()
				}
				if (this.rushType == "budan") {
					//补单
					this.ensureMain()
				}
			},
			/* rushCard() {
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.item.id,
						note: this.note
					}
					this.dialogloading = true
					this.$api.rushCard(data).then(res => {
						this.dialogloading = false
						if (res.status == 1) {
							this.$set(this.tableData[this.pIndex].cardItem[this.sIndex], 'is_rush', 1)
							this.$set(this.tableData[this.pIndex].cardItem[this.sIndex], 'state', 7)
							this.$set(this.tableData[this.pIndex].cardItem[this.sIndex], 'state_name', this.stateArr[7])
							this.close2()
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消冲正订单'
					});
				});
			}, */
			orderRush() {
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.item.id,
						note: this.note
					}
					this.dialogloading = true
					this.$api.orderRush(data).then(res => {
						this.dialogloading = false
						if (res.status == 1) {
							this.close2();
							this.$set(this.tableData[this.pIndex], 'is_rush', 1)
							this.$set(this.tableData[this.pIndex], 'state', 7)
							this.$set(this.tableData[this.pIndex], 'state_name', this.stateArr[7])
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消冲正订单'
					});
				});
			},
			suerOrder(item, pIndex) {
				this.pIndex = pIndex
				// this.sIndex = sIndex
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.ensureOrder(data).then(res => {
						this.loading = false
						if (res.status == 1) {
							let nowTime = this.$util.timestampToTime(new Date().getTime());
							let username = local.get("fourusername")
							this.$set(this.tableData[this.pIndex], 'state', 1)
							this.$set(this.tableData[this.pIndex], 'state_name', this.stateArr[1])
							this.$set(this.tableData[this.pIndex], 'is_manual', 1)
							this.$set(this.tableData[this.pIndex], 'action_time', nowTime)
							this.$set(this.tableData[this.pIndex], 'auditor', username)
							// this.orderOutList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消确认订单'
					});
				});
			},
			// 关闭订单的确认框
			closeOpenDialog(item, pIndex){
				this.pIndex = pIndex
				this.closeItem = item
				this.dialogVisible3 = true
			},
			close3() {
				this.dialogVisible3 = false
				this.closeReason = ""
				this.googleCode = ""
				this.closeItem = []
				this.pIndex = ''
				this.pwRadio = '1'
			},
			
			//关闭主订单
			closeMainOrder() {
				let data = {
					water_no: this.closeItem.water_no,
					status: this.pwRadio,
					googleCode: this.googleCode
				}
				this.loading = true
				this.$api.updateHfOrderStatus(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.orderOutList()
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close3()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			
			// 解绑通道的确认框
			unbildOpenDialog(item, pIndex){
				this.pIndex = pIndex
				this.unbildItem = item
				this.dialogVisible4 = true
			},
			close4() {
				this.dialogVisible4 = false
				this.unbildReason = ""
				this.googleCode = ""
				this.unbildItem = []
				this.pIndex = ''
			},
			
			// 解绑通道
			unbildMainOrder() {
				let data = {
					water_no: this.unbildItem.water_no,
					payway_type_id: this.unbildItem.payway_type_id,
					note: this.unbindReason,
					googleCode: this.googleCode
				}
				this.loading = true
				this.$api.unbindPayway(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.$set(this.tableData[this.pIndex], 'payway_type_id', 0)
						this.$set(this.tableData[this.pIndex], 'payway_name', '')
						this.orderOutList()
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close4()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
		
			//重新派卡
			/* disCard(item, pIndex, sIndex) {
				this.pIndex = pIndex
				this.sIndex = sIndex
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.disCard(data).then(res => {
						this.loading = false
						if (res.status == 1) {
							// this.orderOutList()
							this.$set(this.tableData[this.pIndex].cardItem[this.sIndex], 'again_dis_card', 0)
							this.$set(this.tableData[this.pIndex].cardItem[this.sIndex], 'is_discard', 1)
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消订单重新派卡'
					});
				});

			}, */
			noticeOut(item){
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.noticeOut(data).then(res => {
						this.loading = false;
						if (res.status == 1) {
							this.$message ({
								type: 'success',
								message: this.$t('main.common.successConfirm')
							})
						} else {
							this.$message ({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消发送回调'
					});
				});
			},
			// 补订单确认
			ensureMain(){
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.tableData[this.pIndex].id,
						note: this.note
					}
					console.log(data)
					this.$api.ensureMain(data).then(res => {
						this.dialogloading = false
						this.$set(this.tableData[this.pIndex], 'state', 1)
						this.$set(this.tableData[this.pIndex], 'state_name', this.stateArr[1])
						this.$set(this.tableData[this.pIndex], 'is_rush', 0)
						this.$set(this.tableData[this.pIndex], 'note', this.note)
						if (res.status == 1) {
							this.close2()
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
						
					})
					// this.dialogloading = true
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消补单'
					});
				});
			},
			//审核按钮
			review(item, index) {
				this.dataIndex = index
				console.log(this.dataIndex)
				this.item = item
				this.reviewVisible = true
			},
			reviewClose() {
				this.reviewVisible = false
				this.item = {}
			},
			reviewPass() {
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					//审核通过
					let data = {
						id: this.item.id,
						type: 1
					}
					this.reviewloading = true;
					// this.tableData[this.dataIndex].state = 2
			
					this.checkMentionOrder(data, 1)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消审核'
					});
				});
			},
			reviewCloseOrder() {
				this.$confirm('确认关闭审核吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//审核关闭
					let data = {
						id: this.item.id,
						type: 2
					}
					this.reviewloading = true;
					this.checkMentionOrder(data, 2)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消关闭审核'
					});
				});
			},
			checkMentionOrder(data, type) {
				this.$api.checkMentionOrder(data).then(res => {
					this.reviewloading = false;
					if (res.status == 1) {
						this.reviewClose()
						let nowTime = this.$util.timestampToTime(new Date().getTime());
						let username = local.get("username")
						this.orderOutList()
						this.$message({
							type: "success",
							message: res.msg
						})
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.reviewloading = false;
					this.orderOutList()
			
				})
			},
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style>
	.yctable{
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.yctable:hover {
	  overflow: visible; /* 鼠标悬停时显示全部内容 */
	  white-space: normal; /* 允许文字换行 */
	  text-overflow: clip; /* 移除省略号 */
	}    
	.custom-tag {
	  line-height: 0.9375rem;
	  white-space: normal;
	  height:auto;
	}
	.el-tooltip__popper {
	  white-space: pre-wrap; /* 保留空格和换行符 */
	  word-wrap: break-word; /* 根据单词长度换行 */
	  overflow-wrap: break-word; /* 根据单词长度换行 */
	  max-width: 300px; /* 限制最大宽度 */
	  text-overflow: ellipsis; /* 超过最大宽度时显示省略号（可选） */
	}
</style>
